'use client'

import useQueryParams from '@/hooks/useQueryParams';
import { type Filter } from '.'

import { useSearchParams } from 'next/navigation'
import Offcanvas from 'react-bootstrap/Offcanvas';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import Container from 'react-bootstrap/Container';
import React, { forwardRef, useEffect } from 'react';
import Form from 'react-bootstrap/esm/Form';
import classNames from 'classnames';
import Button from '@components/atoms/button';
import debounce from 'debounce';
import filterQueryParams from '@utils/filter-query-params';

const FilterCheckDropdownItem = forwardRef(
    ({ children, id, checked, onChange }: any, ref: any) => {
        return (
            <Form.Group ref={ref} className={'dropdown-item  mb-0'} controlId={id}>
                <Form.Check
                    type={'checkbox'}
                    label={children}
                    checked={checked}
                    onChange={onChange}
                    role={'checkbox'}
                    aria-checked={checked}
                    className={'d-inline-flex  align-items-center  gap-2'}
                />
            </Form.Group>
        );
    }
);
FilterCheckDropdownItem.displayName = 'FilterCheckDropdownItem'

const ButtonGroup = forwardRef(({ className, children }: any, ref: any) => {
    return (
        <div ref={ref} role={'group'} className={classNames('btn-group', { [`${className}`]: className })}>
            {children.map((child: any, index: string | number) => React.cloneElement(child, {
                key: index,
                ...('DropdownToggle' === child.type.displayName && { className: 'd-flex  justify-content-between  align-items-center  border-gray-400  fs-8  icon--transition' })
            }))}
        </div>
    )
})
ButtonGroup.displayName = 'ButtonGroup'


const Filter = ({ label: { header, reset }, aggregations, currentFilters }: Filter) => {
    const searchParams = useSearchParams()
    const { setQueryParam, removeQueryParamValue, removeAllQueryParams } = useQueryParams()

    const showFilter: boolean = (currentFilters?.length > 0 || '1' === searchParams.get('filter'))
    const showCurrentFilters: boolean = Array.from(filterQueryParams(searchParams, aggregations).keys()).length > 0
    const showResetFilters: boolean = Array.from(filterQueryParams(searchParams, aggregations, ['w', 'l', 'h']).keys()).length > 0

    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        setQueryParam('filter', false === showFilter ? '1' : null)
    }

    const handleChecked = (eventKey: string, event: any) => {
        const [paramName, paramValue] = eventKey.split('|')
        if (true === event.currentTarget.checked) {
            setQueryParam(paramName, paramValue, true)
        } else if (false === event.currentTarget.checked) {
            removeQueryParamValue(paramName, paramValue)
        }
    }

    const isChecked = (param: string, value: string) => {
        const params = new URLSearchParams(searchParams);
        return params.get(param)?.toString()?.split('|').includes(value) ?? false
    }

    const renderFinderLabel = (key: string, value: string | number) => {
        switch (key) {
            case 'l':
                return 'Produktfinder Länge: ' + value + ' mm';
            case 'w':
                return 'Produktfinder Breite: ' + value + ' mm';
            case 'h':
                return 'Produktfinder Höhe: ' + value + ' mm';
            default:
                return 'Produktfinder';
        }
    }

    useEffect(() => {
        if (true === showFilter) {
            const scroll = debounce(function () {
                const elem = document.getElementById('listing');
                window.scrollTo({ top: Math.max(0, (elem?.offsetTop ?? 0) - 170), behavior: 'smooth', });
            }, 200);

            scroll()
        }
    }, [showFilter])

    return (
        <Offcanvas show={showFilter || showCurrentFilters} placement={'bottom'} backdrop={false} scroll={true} onHide={handleClose}>
            <Offcanvas.Header className={'container-fluid  border-bottom'}>
                <Container className={'d-flex  justify-content-between  align-items-center'}>
                    <Offcanvas.Title className={'d-inline-flex  icon  icon--filter  icon--size__sm  icon--valign__middle'}>{header}</Offcanvas.Title>
                    <div className={classNames('cursor--pointer  icon  icon--size__md  icon--valign__middle', { 'icon--chevron-down': showFilter, 'icon--chevron-up': !showFilter })} onClick={handleClose} />
                </Container>
            </Offcanvas.Header>
            <Container fluid>
                <div className={classNames('filter  container  pt-2', { 'visually-hidden': false === showFilter, 'mb-2': false === showCurrentFilters })}>
                    <div className={'row row-cols-lg-4 row-cols-md-2 row-cols-1 overflow-visible'}>
                        {aggregations?.map(({ id, name: attributeName, options }) => (
                            <DropdownButton
                                as={ButtonGroup}
                                key={id}
                                drop={'up'}
                                align={'start'}
                                variant={'outline-dark'}
                                title={attributeName}
                                className={'mb-1'}
                            >
                                {options
                                    .sort(({ name: a }, { name: b }) =>
                                        a.replace(/[.\'’]/, '').localeCompare(b.replace(/[.\'’]/, ''), undefined, { numeric: true, sensitivity: 'base' }))
                                    .map(({ disabled, name }) => (
                                        <DropdownItem
                                            id={name}
                                            key={name}
                                            active={isChecked(attributeName, name)}
                                            checked={isChecked(attributeName, name)}
                                            as={FilterCheckDropdownItem}
                                            onChange={(event) => handleChecked(attributeName + '|' + name, event)}
                                            disabled={disabled}
                                        >
                                            {name}
                                        </DropdownItem>
                                    ))}
                            </DropdownButton>
                        ))}
                    </div>
                </div>

                <Container className={classNames('flex-wrap  gap-2  my-3', { 'd-none': false === showCurrentFilters, 'd-flex': true === showCurrentFilters })} aria-hidden={false === showCurrentFilters}>
                    {Array.from(filterQueryParams(searchParams, aggregations).keys()).map((key: string) => {
                        const values = searchParams.get(key)?.split('|')
                        const disabled = ['l', 'h', 'w'].includes(key)

                        return values?.map((value: string) => (
                            <button key={'current_filter_' + key + '_' + value} className={classNames('d-inline-flex  justify-content-between  align-items-center  w-100  w-md-auto  position-relative  fw-bold  btn  btn-outline-dark', { 'btn--inactive': disabled })} onClick={() => false === disabled ? removeQueryParamValue(key, value) : () => { }}>
                                <span className={classNames(null, {
                                    'me-1 text-gray-400': disabled,
                                    'icon  icon--x  icon--size__md  icon--valign__middle  icon--transition  icon--reversed': !disabled,
                                })}>{`${['l', 'h', 'w'].includes(key) ? renderFinderLabel(key, value) : (key + ': ' + value)}`}</span>
                            </button>
                        ))
                    })}

                    {showResetFilters && (
                        <Button className={'justify-content-between  align-items-center  w-100  w-md-auto  fw-bold'} variant={'dark'} onClick={() => removeAllQueryParams(Array.from(filterQueryParams(searchParams, aggregations, ['w', 'l', 'h']).keys()))}>
                            <span className={'text-left  w-100  text-nowrap  icon  icon--x  icon--white  icon--size__md  icon--reversed  icon--valign__middle  icon--transition'}>{reset}</span>
                        </Button>
                    )}
                </Container>
            </Container>
        </Offcanvas >
    )
}

export default Filter

