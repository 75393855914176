export default function fitlerQueryParams(searchParams: URLSearchParams, aggregations: { name: string }[] = [], ignore: string[] = []) {
    const params = new URLSearchParams(searchParams);

    params.forEach((value, key) => {
        if (!value) {
            params.delete(key);
        }
    });

    ([...ignore, ...(process.env.REQUEST_FILTER_EXCLUDED ?? process.env.NEXT_PUBLIC_REQUEST_FILTER_EXCLUDED ?? '').split(',')]).forEach((key: string) => params.delete(key))

    return params;
}
